<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('segiForm')" @cancel="cancel" :stateFlag="true"></add-header>
    <el-form ref="segiForm" :model="segiForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="登记单类型" prop="x">
            <span>S H</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="汇款客户" prop="cust_abbr">
            <el-input disabled v-model="segiForm.cust_abbr" maxlength="100" show-word-limit placeholder="请填写客户信息">
              <template slot="append">
                <div class="flexHV">
                  <el-link disabled class="vg_mr_8">{{ segiForm.cust_group }}</el-link>
                  <el-link type="primary" @click="dialogTableVisible = true">导入</el-link>
                </div>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款方式" prop="segi_type">
            <el-select v-model="segiForm.segi_type" size="small" placeholder="请选择收款方式" filterable>
              <el-option v-for="item in segiTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款日期:" prop="segi_date">
            <el-date-picker v-model="segiForm.segi_date" format="yyyy-MM-dd" placeholder="选择收款日期" type="date" value-format="timestamp"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="汇款抬头" prop="cptt_bid">
            <el-select v-model="segiForm.cptt_bid" size="small" placeholder="请选择汇款抬头" show-word-limit clearable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select v-model="segiForm.cust_currency" size="small" placeholder="请选择币种" filterable>
              <el-option v-for="item in CompanyList" :key="item.id" :value="item.param1" :label="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="汇率" prop="tran_usdrate">
            <el-input
              v-model="segiForm.tran_usdrate"
              @change="calcEcho"
              @input="segiForm.tran_usdrate = keep6Decimal(segiForm.tran_usdrate)"
              maxlength="8"
              show-word-limit
              placeholder="请填写汇率"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收款金额" prop="segi_total">
            <el-input
              v-model="segiForm.segi_total"
              @change="calcEcho"
              @input="val => (segiForm.segi_total = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="请填写收款金额"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="本币总额" prop="scon_rmbtotal">
            <el-input v-model="segiForm.scon_rmbtotal" maxlength="30" show-word-limit placeholder="请填写本币总额"> </el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="收款单情况" prop="segi_situ">
            <el-select disabled v-model="segiForm.segi_situ" size="small" placeholder="请选择收款单情况" filterable>
              <el-option v-for="item in situList" :key="item.id" :value="item.id" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收款抬头" prop="cptt_aid">
            <el-select @change="cpttChange(1)" v-model="segiForm.cptt_aid" size="small" placeholder="请选择公司名称" filterable>
              <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="收款银行" prop="cptt_bank_name">
            <el-select
              @change="bankChange"
              :disabled="!segiForm.cptt_aid"
              v-model="segiForm.cptt_bank_name"
              size="small"
              placeholder="请选择收款银行"
              filterable
            >
              <el-option v-for="item in bankList" :key="item.cptt_bank_id" :label="item.cptt_bank_name" :value="item.cptt_bank_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="银行账户" prop="cptt_bank_account">
            <el-input disabled v-model="segiForm.cptt_bank_account" show-word-limit placeholder="请填写银行账户"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-dialog title="导入汇款抬头" width="70%" :visible.sync="dialogTableVisible">
        <CustList @importCustChange="importCustChange"></CustList>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { segiAPI } from '@api/modules/segi';
import { cpttAPI } from '@api/modules/comptitle';
import { optnAPI } from '@api/modules/optn';
import helper from '@assets/js/helper';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import { BigNumber } from 'bignumber.js';
import CustList from './Component/CustList.vue';
import { keep2Decimal } from '@assets/js/regExUtil';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SegiAddMain',
  components: {
    addHeader,
    inputUser,
    CustList
  },
  data() {
    return {
      rules: {
        cptt_aid: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_abbr: [{ required: true, trigger: 'blur', message: ' ' }],
        segi_total: [{ required: true, trigger: 'blur', message: ' ' }],
        segi_date: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      segiForm: {
        cust_abbr: null,
        cust_id: null,
        segi_type: 1,
        segi_date: new Date().getTime(),
        segi_total: null,
        cust_currency: 'USD',
        tran_usdrate: 6.3,
        scon_rmbtotal: null,
        cptt_aid: null,
        cptt_aname: null,
        segi_situ: 0,
        cptt_bank_name: null,
        cptt_bank_account: null,
        cptt_bid: null //汇款公司
      },
      segiTypeList: [
        { id: 1, label: '电汇' },
        { id: 2, label: '现金' },
        { id: 3, label: '信用证' },
        { id: 4, label: '承兑' }
      ],
      CompanyList: [],
      cpttList: [],
      situList: [
        { id: 0, label: '未生成' },
        { id: 1, label: '已生成收款单' },
        { id: 2, label: '已生成预收单' }
      ],
      bankList: [],
      dialogTableVisible: false
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    initData() {
      this.getCptt();
      this.getCustDport();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let segiForm = JSON.parse(JSON.stringify(this.segiForm));
      segiForm = Object.assign(segiForm, staffForm);
      post(segiAPI.addSegi, segiForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/segi_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('segiForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('segiForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 选择客户信息
    importCustChange(val) {
      this.segiForm.cust_id = val.cust_id;
      this.segiForm.cust_abbr = val.cust_name;
      this.segiForm.cust_group = val.cust_group;
      this.dialogTableVisible = false;
    },
    // 公司回显银行选项
    cpttChange(val) {
      for (let i = 0; i < this.cpttList.length; i++) {
        if (this.cpttList[i].cptt_id === this.segiForm.cptt_aid) {
          this.bankList = this.cpttList[i].cptt_bank_list;
          this.segiForm.cptt_aname = this.cpttList[i].cptt_name;
          if (this.cpttList[i].cptt_bank_list.length > 0) {
            this.segiForm.cptt_bank_name = this.bankList[0].cptt_bank_name;
            this.bankChange();
          } else {
            this.segiForm.cptt_bank_name = null;
            this.segiForm.cptt_bank_account = null;
          }
        }
      }
    },
    // 回显银行账户
    bankChange() {
      for (let i = 0; i < this.bankList.length; i++) {
        if (this.bankList[i].cptt_bank_name === this.segiForm.cptt_bank_name) {
          this.segiForm.cptt_bank_account = this.bankList[i].cptt_bank_code;
        }
      }
    },
    // 计算回显值
    calcEcho() {
      let totalA = new BigNumber(1);
      totalA = totalA.times(this.segiForm.segi_total ? this.segiForm.segi_total : 0).times(this.segiForm.tran_usdrate ? this.segiForm.tran_usdrate : 0);
      totalA = totalA.toFixed(2);
      this.segiForm.scon_rmbtotal = totalA;
    },
    // 获取币种
    getCustDport() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.CompanyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取公司抬头
    getCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
